@keyframes notification {
	0% {
		opacity: 0;
	}
	10%,
	90% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.notifications {
	position: fixed;
	top: 10px;
	right: 50px;
	z-index: 1000;
	display: flex;
	flex-direction: column;
	gap: 20px;

	.notification {
		width: 200px;
		padding: 20px;
		text-align: center;
		border-radius: var(--border-radius);
		animation: notification 10s;

		& > p {
			margin: 0;
		}

		&-success {
			color: #155724;
			background-color: #d4edda;
		}

		&-error {
			color: #721c24;
			background-color: #f8d7da;
		}
	}
}
