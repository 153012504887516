.sidebar {
	flex-basis: var(--menu-width);
	flex-shrink: 0;
	height: calc(100vh - 50px);
	background: var(--main-bg);
	display: flex;
	flex-direction: column;
	margin-top: 50px;
	z-index: 1;
	border-right: 1px solid rgba(#000, 0.1);
	box-shadow: 0 0 7px rgba(#000, 0.2);
	transition: background var(--transition-timing);

	.footer {
		flex-basis: 85px;
		flex-shrink: 0;
		box-shadow: 0px 0px 4px rgba(15, 15, 15, 0.1);
		padding: 12px 16px 8px 16px;
		height: 200px;

		.copyright {
			color: var(--text-lighter);
			font-size: 0.928rem;
			margin-top: 10px;

			&-version {
				margin-bottom: 4px;
			}
		}

		.theme {
			display: flex;
			gap: 20px;
			align-items: center;
			justify-content: space-between;

			span {
				font-weight: 500;
				color: var(--text-light);
			}

			.themes {
				width: 48px;
				height: 24px;
				background-color: var(--toggle-background);
				border-radius: 32px;
				position: relative;
				display: flex;
				align-items: center;
				overflow: hidden;
				cursor: pointer;

				.wrapper-toggle-themes {
					position: absolute;
					width: 100%;
					padding: 4px;
					height: 100%;
					transform: translateX(0%);
					transition: all var(--transition-timing);
					display: flex;
					align-items: center;

					&.dark {
						transform: translateX(100%);

						.toggle-themes {
							transform: translateX(-150%);
						}
					}

					.toggle-themes {
						transition: all var(--transition-timing);
						z-index: 999;
						width: 16px;
						height: 16px;
						position: absolute;
						border-radius: 50%;
						background-color: white;
						transform: translateX(0%);
					}
				}

				.moon,
				.sun {
					display: inline-flex;
					position: absolute;
					transition: opacity 120ms;

					svg {
						height: 14px;
					}
				}

				.sun {
					opacity: 0;
				}

				.moon {
					right: 4px;
					opacity: 1;

					svg {
						*[fill] {
							fill: #d8e8ff;
						}
					}
				}

				&.dark {
					.sun {
						left: 4px;
						opacity: 1;

						svg {
							*[fill] {
								fill: #fce570;
							}
						}
					}

					.moon {
						opacity: 0;
					}
				}
			}
		}
		.copyright-bot {
			line-height: 1;
		}
	}
}

.mobile-overlay {
	display: none;
}

@media (max-width: 1600px) {
	.sidebar {
		position: fixed;
		left: 0;
		top: 0;
		bottom: 0;
		height: auto;
		width: var(--menu-width);
		z-index: 1000;
		transform: translate3d(calc(-1 * var(--menu-width)), 0, 0);
		transition: all var(--transition-timing) ease-out;

		&.active {
			transform: none;
		}
	}

	.mobile-overlay {
		z-index: 900;
		display: none;
		position: fixed;
		left: 0;
		top: 50px;
		right: 0;
		bottom: 0;
		background: rgba(#000, 0.2);
		opacity: 0;
		transition: all var(--transition-timing) ease-out;

		&.active {
			display: block;
			opacity: 1;
		}
	}
}
