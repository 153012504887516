.comment {
	$pl: 30px;
	margin-bottom: 15px;
	display: flex;
	align-items: flex-start;
	gap: 12px;
	font-size: 1rem;

	.attachments {
		.list,
		.new-files {
			margin-top: 5px;
			margin-left: -20px;
		}
	}

	.who {
		flex-basis: $pl;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.what {
		width: 100%;
		flex-grow: 1;
		line-height: 13px;
		border-bottom: var(--border-width) solid var(--border-color);

		.subject {
			margin-right: 10px;
			color: var(--text);
			font-weight: 500;
		}

		.date {
			font-size: 0.93rem;
			margin-top: 4px;
			display: block;
			color: var(--text-lighter);
		}

		.text {
			margin: 10px 0 15px 0;
			line-height: 1.4em;
			background: var(--main-bg);
			word-break: break-word;

			p {
				margin: 0;
			}

			ol,
			ul {
				margin: 0;
				padding: 0 0 0 15px;
			}

			* + ol,
			* + ul,
			ol + *,
			ul + * {
				margin-top: 1.4em;
			}
		}

		.panel-buttons {
			display: grid;
			grid-template-columns: repeat(3, max-content);
			grid-gap: 10px 20px;
			align-items: center;
			width: 70%;
			margin-bottom: 10px;

			.toggle {
				gap: 10px;
			}

			> div {
				display: flex;
				align-items: center;
				margin: 0;

				.upload {
					margin: 0;

					label {
						text-decoration: none;

						span {
							color: var(--text-lighter);
						}
					}

					&:hover {
						span {
							color: var(--primary);
						}

						svg {
							color: var(--primary);
						}
					}
				}
			}

			.errors-and-list {
				flex: 1;
				order: 5;
				grid-column-start: 1;
				grid-column-end: 6;
				flex-direction: column;
				align-items: flex-start;

				.upload-error {
					display: flex;
					gap: 10px;
					justify-content: center;

					.icon {
						display: flex;
						width: 20px;
						align-items: center;

						svg {
							height: 16px;
						}
					}
				}
			}
		}
	}

	.action {
		color: var(--text-light);
	}

	.toggle {
		margin-top: 5px;
		margin-bottom: 2px;
	}
}

.post-comment {
	margin-bottom: 20px;
	position: relative;

	.actions {
		position: absolute;
		bottom: 20px;
		left: 20px;

		button {
			font-size: 1rem;
		}
	}
}

@media (max-width: 600px) {
	.comment {
		.what {
			.panel-buttons {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
			}
		}
	}
}
