.operation-edit-popup {
	position: absolute;
	right: 0;
	top: 50px;
	height: 100%;
	border-radius: unset;

	.main,
	.split-table {
		.total {
			width: 100%;
		}
		.wrong-total {
			background-color: var(--danger);
			color: var(--white);
		}
		.error {
			height: 20px;
		}
	}
	.split {
		border-bottom: var(--border-width) solid var(--secondary-light);
		margin-bottom: 10px;
		padding-bottom: 20px;
	}

	.payment-buttons {
		margin: 10px 0 20px 0;
	}

	.sp-popup-content {
		padding: 40px !important;
		background-color: var(--content-bg);
		height: 100%;
		display: flex;
		flex-direction: column;

		.ui-form {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			.attachments {
				flex-grow: 1;
			}
			.form-submit {
				padding-bottom: 0;
			}
		}

		h2 {
			font-weight: 500;
			font-size: 20px;
			line-height: 23px;
			margin-bottom: 20px;
		}
	}

	textarea {
		max-width: 100%;
		min-width: 100%;
	}

	.sp-date-picker {
		min-width: 100%;
	}
}

.button-group > * {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
