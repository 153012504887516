.ui-pager {
	--pager-disabled-text: var(--pager-disabled);

	display: flex;
	justify-content: flex-end;
	color: var(--text);

	.ui-pager-total {
		color: var(--text);
	}

	a,
	span {
		display: flex;
		justify-content: center;
		align-items: center;
		border: var(--border-width) solid var(--border-color);
		border-radius: var(--border-radius);
		margin-right: 5px;
		width: 36px;
		height: 36px;
		color: var(--text-light);
	}

	a {
		transition: all var(--transition-timing);

		&.first-link {
			margin-left: 0;
		}

		&:hover {
			border: var(--border-width) solid var(--secondary);
			background-color: transparent;
			color: var(--primary);
		}

		&.active {
			border: none;
			background-color: var(--border-color);
			color: var(--text);
		}

		&.disabled {
			border-color: var(--pager-disabled);
			opacity: 0.5;
		}
	}
}
