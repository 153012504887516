.modes {
	display: flex;
	column-gap: 10px;
	svg {
		background-color: transparent;
		border-radius: 5px;
		border: var(--border-width) solid var(--section-header-color);
		cursor: pointer;
		transition: all var(--transition-timing) ease-out;
		width: var(--ui-filter-height);
		height: var(--ui-filter-height);
		.icon-border {
			stroke: unset;
		}
		.icon-content {
			stroke: var(--icon-content-inactive);
		}
		&:hover {
			border-color: var(--secondary);
			.icon-content {
				stroke: var(--primary);
			}
		}
		&.active {
			pointer-events: none;
			opacity: 1;
			 border-color: var(--section-header-color);
			background-color: var(--section-header-color);
			.icon-content {
				stroke: var(--primary);
			}
		}
	}
}
