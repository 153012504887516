.process-page {
	.ui-form {
		position: relative;

		.process {
			width: 100%;
			background-color: var(--main-bg);
			min-height: 300px;
			border-radius: var(--border-radius);
			position: relative;

			&-header {
				background-color: var(--section-header-color);
				min-height: 48px;
				display: flex;
				align-items: center;
				padding: 6px 20px;
				border-radius: var(--border-radius) var(--border-radius) 0 0;
				justify-content: space-between;

				.form-field {
					width: 100%;
					margin: 0;
					flex-basis: 380px;

					.name-value {
						font-size: 18px;
						font-weight: 700;
						line-height: 21px;
						color: var(--text);
					}
				}
			}

			&-description-equipment {
				padding: 14px 20px;

				.form-field {
					&:first-child {
						max-width: 380px;
					}

					.description {
						margin-bottom: 60px;
					}
				}

				.switch-description {
					margin-top: 16px;
					padding-bottom: 40px;
				}

				.action-controls {
					position: absolute;
					margin-bottom: 30px;
					bottom: 0;

					.action-controls-save {
						margin-right: 20px;
					}
				}
			}
		}
	}
}
