.log {
	margin-bottom: 20px;
	display: flex;
	align-items: flex-start;
	gap: 12px;
	font-size: 14px;

	.who {
		flex-basis: 30px;
		flex-shrink: 0;
		font-weight: 400;
	}

	.what {
		flex-grow: 1;
		line-height: 13px;
		padding-bottom: 10px;
		border-bottom: var(--border-width) solid var(--border-color);

		.subject {
			color: var(--text);
			margin-right: 10px;
			font-weight: 500;

			.render-status {
				margin-left: 5px;
			}
		}

		.action {
			color: var(--text-light);
			padding-right: 16px;

			a {
				position: relative;

				& > div {
					cursor: default;
					position: absolute;
					min-width: 100px;
					max-width: 300px;
					line-height: 1.3em;
					min-height: 32px;
					max-height: 200px;
					overflow: auto;
					bottom: 18px;
					left: 0;
					background: #fffcf0;
					padding: 10px;
					border: var(--border-width) solid rgba(#000, 0.2);
					box-shadow: 1px 3px 3px rgba(#000, 0.1);
					color: #000;

					a {
						color: #000;
						pointer-events: none;
					}
				}
			}
		}

		.date {
			margin-top: 4px;
			display: block;
			color: var(--text-lighter);
		}
	}
}
