$max-width-popover: 300px;
$max-height-popover: 150px;
$default-padding-popover-popup: 10px;

.page {
	display: flex;
	flex-direction: column;

	.orders-root {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		height: 100%;

		.orders-list {
			flex-grow: 1;
			display: flex;
			flex-direction: column;

			.orders-list-wrapper {
				flex-grow: 1;
				display: flex;
				flex-direction: column;

				#table {
					height: unset;
					flex-grow: 1;
				}
			}
		}
	}
}
.popover-order-comment{
	max-width: $max-width-popover;
	max-height: $max-height-popover;
	overflow-y: auto;
}
