.documents-root {
	height: 100%;

	hr {
		background-color: var(--secondary-light);
		height: var(--border-width);
	}
}

.documents-tabs-info {
	height: initial !important;
}
