.sp-popup {
	--popup-overlay-bg: rgba(0, 0, 0, 0.3);
	--popup-bg: var(--content-bg);
	--popup-text: var(--text);
	--popup-header-bg: var(--primary);
	--popup-header-text: var(--text-primary);
	--popup-border-radius: var(--border-radius);
	--popup-margin: 15px;
	--popup-padding: 15px;
	--popup-header-height: 34px;

	&-content {
		border-radius: var(--border-radius);
		background-color: var(--main-bg);
	}
}
