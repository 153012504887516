.sp-popover-popup {
	--popover-bg: var(--main-bg);
	--popover-text: var(--text);
	border: var(--border-width) solid var(--border-color);

	.sp-popover-arrow {
		&::after {
			border: var(--border-width) solid var(--border-color);
		}
	}
}
