.documents-root {
	height: unset;
	flex-grow: 1;
	display: flex;
	flex-direction: column;

	.documents-list {
		flex-grow: 1;
		display: flex;
		flex-direction: column;

		table {
			tbody {
				.string-value,
				.number-value {
					font-weight: 400;
					font-size: 14px;
					line-height: 16px;
					word-wrap: break-word;
					overflow-wrap: break-word;
					white-space: unset;
				}

				.labels {
					:not(:first-child) {
						margin-left: 5px;
					}
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.documents-list {
		tr th:nth-child(1),
		tr td:nth-child(1),
		tr th:nth-child(2),
		tr td:nth-child(2),
		tr th:nth-child(3),
		tr th:nth-child(3) {
			div {
				white-space: pre-wrap;
			}
		}
	}
}

@media (max-width: 600px) {
	.documents-toolbar {
		flex-wrap: wrap;

		.toolbar {
			flex-wrap: wrap;
			flex-basis: auto;
			gap: 10px;

			.toolbar-create,
			.toolbar-search {
				width: 100%;
			}
		}
	}

	.documents-list {
		tbody .border {
			display: none;
		}

		.string-value {
			word-wrap: break-word;
			overflow-wrap: break-word;
		}

		.contragent {
			white-space: unset;
		}

		tr th:nth-child(3),
		tr td:nth-child(3),
		tr th:nth-child(6),
		tr td:nth-child(6),
		tr th:nth-child(7),
		tr td:nth-child(7) {
			display: none;
		}

		tr th:nth-child(5) {
			width: 17%;
			.column-header {
				justify-content: flex-end;
			}
		}
		tr td:nth-child(5) {
			.string-value {
				display: flex;
				justify-content: flex-end;
			}
		}
	}
}

