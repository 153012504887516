.finance-operations {
	.table {
		tr {
			td:first-child {
				padding: 0 !important;
			}

			border-color: transparent;
		}
		.sp-table td {
			padding-top: 5px;
			padding-bottom: 5px;
		}
		.bankAccount {
			color: var(--text-lighter);
			font-size: 12px !important;
		}

		.empty {
			background-color: var(--content-bg) !important;
			height: 50px;
			display: block;
		}

		.expand {
			margin: 10px;
			background-color: var(--table-cell-bg);
			display: flex;
			align-items: center;
			justify-content: center;
			width: 20px;
			height: 20px;
			font-size: 15px;
			border-radius: 50%;
			background-color: var(--text-lighter);
			color: var(--content-bg);
			&:hover {
				background-color: var(--primary) !important;
			}
		}
		.arrow-right {
			width: 100%;
			margin: 0 auto;
		}
	}
	.not-found {
		margin-top: 10px;
		display: flex;
		justify-content: center;
		font-weight: bold;
	}
}
