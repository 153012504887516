.sp-date-picker {
	height: var(--ui-filter-height);
	min-width: 145px;
	width: 100%;

	input {
		padding: 12px;
		border: none;
		border: var(--border-width) solid var(--border-color);
		font-size: 13px;
		color: var(--date-picker-text) !important;

		&:hover,
		&:active {
			background: var(--date-picker-bg) !important;
			border: var(--border-width) solid var(--date-picker-hover-border-color) !important;
		}
	}

	&.active {
		input {
			background: var(--date-picker-bg);
			border: var(--border-width) solid var(--date-picker-hover-border-color) !important;
		}
	}

	.clear-btn {
		right: 10px;
	}

	&.empty::before {
		content: '';
		position: absolute;
		width: 18px;
		height: 18px;
		top: 9px;
		right: 10px;
		background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='18' height='18' viewBox='0 0 19 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M15.4 2.80005H2.8C1.80589 2.80005 1 3.60594 1 4.60005V17.2C1 18.1942 1.80589 19 2.8 19H15.4C16.3941 19 17.2 18.1942 17.2 17.2V4.60005C17.2 3.60594 16.3941 2.80005 15.4 2.80005Z' stroke='%23B8B8B8' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M12.7 1V4.6' stroke='%23B8B8B8' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M5.5 1V4.6' stroke='%23B8B8B8' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M1 8.19995H17.2' stroke='%23B8B8B8' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e ");
	}

	--date-picker-text: var(--input-text);
	--date-picker-bg: var(--input-bg);
	--date-picker-secondary-text: var(--text-light);
	--date-picker-secondary-bg: var(--content-bg);
	--date-picker-tab-text: var(--text);
	--date-picker-tab-bg: var(--content-bg);
	--date-picker-tab-hover-text: var(--text);
	--date-picker-tab-hover-bg: var(--main-bg);
	--date-picker-border-color: rgba(0, 0, 0, 0.15);
	--date-picker-hover-border-color: var(--primary);
	--date-picker-dropdown-bg: var(--main-bg);
	--date-picker-dropdown-border-color: var(--border-color);
	--date-picker-active-border-color: var(--primary);
	--date-picker-border-radius: 4px;
	--date-picker-shadow: var(--text);
	--date-picker-btn-text: var(--text);
	--date-picker-btn-bg: var(--deep-dark);
	--date-picker-btn-hover-bg: var(--dark);
	--date-picker-ui-font-size: 12px;

	--calendar-day-width: 3em;
	--calendar-day-height: 2.5em;
	--calendar-day-hover-text: var(--primary);
	--calendar-day-hover-bg: rgba(var(--primary-rgb), 0.2);
	--calendar-day-hover-border-color: var(--primary);
	--calendar-today-text: rgba(var(--primary-rgb), 0.9);
	--calendar-today-bg: rgba(var(--primary-rgb), 0.2);
	--calendar-today-border-color: rgba(var(--primary-rgb), 0.4);

	--clock-size: 18em;
	--clock-hover-text: var(--text-primary);
	--clock-hover-bg: var(--primary);
	--clock-selected-bg: var(--secondary);
	--clock-selected-color: var(--text-primary);
	--clock-minute-bg: rgba(var(--primary-rgb), 0.2);
	--clock-minute-text: var(--text);
	--color-small-minute-bg: var(--dark-grey);
	--color-small-minute-text: transparent;
	--clock-am-hour-bg: rgba(var(--primary-rgb), 0.2);
	--clock-am-hour-text: var(--text);
	--clock-pm-hour-bg: var(--main-bg);
	--clock-pm-hour-text: var(--text);
	--clock-value-bg: var(--primary);
	--clock-value-text: var(--text-primary);
	--clock-arrow-color: var(--primary);
}
