.auth-logo {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 336px;
	height: 40px;
	margin-bottom: 88px;

	.logo-icon-wrapper {
		width: 40px;
		height: 100%;
		margin-right: 13px;

		svg {
			width: 100%;
			height: 100%;
			margin: 0;
		}
	}

	.logo-text-wrapper {
		height: 100%;
		flex: 1;
		display: flex;

		svg {
			&:first-child {
				flex: 1;
				width: 100%;
				height: 100%;
			}
		}

		.logo-erp {
			width: 93px;
			height: 100%;
			margin: 0;
		}
	}
}
