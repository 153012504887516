.ui-checkbox {
    --checkbox-checked-bg: var(--primary);
    --checkbox-checked-border-color: var(--primary);
    --checkbox-bg: var(--main-bg);
    --checkbox-border-color: var(--text-lighter);
    border-radius: 5px;

    &-toggle {
        min-width: 20px;
        min-height: 20px;
        margin-right: 10px;
        svg {
            width: 16px;
            height: 16px;
        }
    }
    &.checked {
        svg {
            *[fill] {
                fill: #fff;
            }
        }
    }
}
