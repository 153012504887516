.topbar {
	position: fixed;
	width: 100vw;
	height: 50px;
	z-index: 2;
	background: var(--main-bg);
	color: var(--text);
	padding: 0;
	display: flex;
	align-items: center;
	border-bottom: 1px solid var(--border-color);

	.header {
		flex-basis: var(--menu-width);
		transition: background var(--transition-timing);

		.mobile-menu {
			display: none;
		}

		.title {
			height: 50px;
			white-space: nowrap;
			padding-left: 15px;
			display: flex;
			align-items: center;
			border-bottom: 1px solid rgba(#000, 0.05);
			border-right: 1px solid rgba(#000, 0.05);
			background: var(--header-title-bg);
			color: var(--header-text);

			.logo {
				// height: 34px;
				text-align: center;
				margin-right: 10px;
				display: flex;
				align-items: center;
				justify-content: center;

				a {
					display: flex;
				}
			}
		}
	}

	.page-title {
		flex-grow: 1;
		padding-left: 20px;
	}

	.toolbar {
		flex-grow: 1;
		display: flex;
		align-items: center;
		padding-left: 20px;
		height: 50px;
		background: var(--toolbar);
		color: var(--toolbar-text);
		transition: background var(--transition-timing);
		justify-content: flex-end;
	}

	.toolbar-left {
		font-size: 1.143rem;
		flex-grow: 0.8;
	}

	.toolbar-right {
		flex-grow: 0.2;
		display: flex;
		justify-content: flex-end;
		flex-shrink: 0;
		padding-right: 20px;

		a {
			color: var(--header-link);

			&:hover {
				color: var(--header-link-hover);
			}
		}

		.links {
			a {
				display: inline-block;
				vertical-align: middle;
				text-decoration: underline;
				margin-left: 10px;
			}
		}

		.theme-switch {
			display: inline-block;
			width: 120px;
		}

		.user-section {
			display: flex;
			align-items: center;
			border-left: 1px dashed rgba(#fff, 0.5);
			position: relative;
			height: 30px;
			cursor: pointer;

			&:hover {
				//background: #ddd;
			}

			.profile-link {
				display: inline-block;
				text-decoration: none;
				padding-left: 10px;
				padding-right: 10px;
				height: 30px;
				line-height: 30px;
				border-radius: 15px;

				&:hover {
					background: rgba(#000, 0.1);
				}
			}

			.avatar {
				margin-right: 10px;
			}

			& > a {
				margin-left: 10px;
				text-decoration: underline;
			}

			.user-menu-btn {
				display: inline-block;
				margin-left: 10px;
				width: 32px;
				height: 32px;
				line-height: 32px;
				border-radius: 50%;
				cursor: pointer;
				color: var(--header-link);
				text-align: center;

				&:hover {
					color: var(--header-hover);
				}

				&.inverted {
					transform: scale(1, -1);
				}
			}

			.user-menu {
				position: absolute;
				right: 0;
				top: 49px;
				min-width: 200px;
				background: #eee;
				padding: 5px 10px;
				border: var(--border-width) solid #ccc;

				a {
					display: block;
					height: 30px;
					line-height: 30px;
					padding: 0 5px;

					& > span {
						display: inline-block;
						width: 20px;
					}

					&:hover {
						text-decoration: none;
						background: #ddd;
					}
				}
			}
		}
	}
}

@media (max-width: 1600px) {
	.topbar {
		background: var(--toolbar);
		color: var(--toolbar-text);
		padding: 0;

		.header {
			display: flex;

			.mobile-menu {
				display: block;
				flex-basis: 50px;
				height: 50px;
				line-height: 50px;
				font-size: 1.143rem;
				text-align: center;
				cursor: pointer;
			}

			.title-mobile {
				display: block;
			}
		}

		.toolbar {
			flex-grow: 1;

			.toolbar-right {
				flex-grow: 0.3;

				.links {
					display: none;
				}

				.user-section {
					border: 0;

					.fio {
						display: none;
					}
					.logout {
						margin: 0;
					}
				}
			}
		}
	}
}

.logo-icon {
	margin-right: 6px;

	*[fill] {
		&:first-child {
			transition: all var(--transition-timing);
			fill: var(--text);
		}
	}
}

.logo-erp {
	margin-left: 3px;

	*[fill] {
		transition: all var(--transition-timing);
		fill: var(--text);
	}
}
