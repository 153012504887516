.dark-theme {
	//*scss переменные - основные

	$white: #ffffff;
	$secondary: #2784ff;
	$primary: #33ce65;

	$dark-color-white: #ececec;
	$dark-color-blue: #34405f;
	$dark-color-blue-dark: #29324b;
	$dark-color-blue-light: rgba($secondary, 0.4);
	$dark-color-grey: #c0c6d4;
	$dark-color-grey-dark: #818796;

	$success: #{$primary};
	$danger: #fc5469;
	$info: #{$secondary};
	$warning: #ff9900;

	//*scss переменные - производные

	$login-background-layout: rgba($secondary, 0.7);
	$main-bg-hover: rgba($secondary, 0.05);
	$skeleton-list-color: rgba($dark-color-blue-dark, 0.45);
	$button-default-hover-bg: rgba($dark-color-blue-light, 0.1);

	//*css переменные - основные цвета

	--color-white: #{$dark-color-white};
	--color-grey: #{$dark-color-grey};
	--color-grey-dark: #{$dark-color-grey-dark};
	--color-blue: #{$dark-color-blue};
	--color-blue-dark: #{$dark-color-blue-dark};
	--color-blue-light: #{$dark-color-blue-light};
	--primary: #{$primary};
	--secondary: #{$secondary};
	--white: #{$white};

	--success: #{$primary};
	--danger: #{$danger};
	--info: #{$info};
	--warning: #{$warning};

	//*css переменные - произвоные переменные

	--skeleton-shine: #{$skeleton-list-color};
	--skeleton-bg: var(--main-bg);

	--button-hover-default-bg: #{$button-default-hover-bg};
	--button-default-border-color: var(--border-color);

	--text: var(--color-white);
	--text-light: var(--color-grey);
	--text-lighter: var(--color-grey-dark);
	--text-primary: var(--color-blue);

	--pager-text: var(--dark-grey);
	--pager-disabled: var(--color-grey-dark);

	--main-bg: var(--color-blue);
	--main-bg-hover: #{$main-bg-hover}; //?!
	--content-bg: var(--color-blue-dark);
	--secondary-bg: var(--color-blue-light);

	--primary-rgb: 51, 206, 101;

	--toolbar: var(--primary);
	--toolbar-text: var(--white);

	--secondary-light: var(--color-blue-dark);
	--secondary-lighter: var(--color-blue-light);

	--border-color: var(--color-blue-light);
	--border-disabled: var(--color-grey);

	--shadow: 0 1px 3px rgba(#000, 0.15);

	--icon-border: var(--secondary);
	--icon-fill: var(--color-blue);
	--icon-content: var(--primary);
	--icon-border-inactive: var(--icon-border);
	--icon-content-inactive: var(--color-grey);
	--icon-disabled: var(--color-grey-dark);

	--menu-width: 240px;

	--kanban-header-text: var(--color-blue);
	--kanban-card-text: var(--color-grey-dark);
	--kanban-parent-rgb: 41, 50, 75;

	--scrollbar-thumb: rgba(0, 0, 0, 0.1);
	--scrollbar-thumb-hover: rgba(0, 0, 0, 0.3);
	--scrollbar-track: transparent;

	--input-bg: var(--color-blue);
	--input-disabled-bg: #3f4b6a;
	--input-text: var(--text);
	--input-disabled-text: var(--color-grey);

	--dropdown-text: var(--text-light);
	--dropdown-bg: var(--color-blue);
	--dropdown-disabled-bg: var(--input-disabled-bg);

	--placeholder: var(--dark-grey);

	--svg-attachment-color: var(--color-grey);

	--form-label-color: var(--color-grey);

	--toggle-background: var(--secondary);

	--section-header-color: var(--border-color);

	--login-background-layout: #{$login-background-layout};

	background: var(--main-bg);

	//?используется в ui-old
	--dropdown-bg: var(--main-bg);
}
