.orders-report {
	padding-bottom: 20px;

	.toolbar {
		margin: var(--ui-filter-margin) 0;

		.action-bar {
			display: flex;
			gap: 20px;
			align-items: flex-end;

			.form-field {
				margin: 0;

				.sp-trigger {
					height: 28px;

					.filter {
						height: 100%;
					}
				}
			}

			.actions {
				display: flex;
				gap: 10px;

				> button {
					margin: 0;
				}
			}
		}
	}

	.popover-table{
		display: block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.link-number-order{
		font-size: 11px;
		text-decoration: none;
		color: var(--form-text-accent, #0af);
	}

	.sp-table tr{
		cursor: pointer;
	}
}

.order-positions {
	display: flex;
	flex-direction: column;
}
