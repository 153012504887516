.documents-toolbar {
	display: flex;
	justify-content: space-between;
	margin-bottom: var(--ui-toolbar-padding-bot);
	padding-bottom: 10px;
	border-bottom: var(--border-width) solid var(--border-color);
}

.toolbar-filter-popup {
	margin: 5px 5px;

	.filter-buttons {
		display: flex;
		gap: 20px;
		.filter-apply {
			font-weight: 700;
			font-size: 14px;
			line-height: 16px;
			text-transform: uppercase;
			background-color: var(--default-border);
			color: #ffffff;
		}
	}

	.filters {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		row-gap: 10px;

		.filter-header {
			display: flex;
			flex-direction: column;

			.filter-item {
				display: flex;
				align-items: center;
				font-weight: 400;
				font-size: 14px;
				line-height: 16px;
				color: #32323299;
				margin-bottom: 12px;

				input {
					margin-left: 0;
					margin-right: 10px;
					width: 18px;
					height: 18px;
				}
			}

			.filter-status-label {
				font-weight: 500;
				font-size: 14px;
				line-height: 17px;
				margin-bottom: 20px;
			}
		}
	}
}

@media (max-width: 767px) {
	.documents-toolbar {
		flex-wrap: wrap;
	}
}
