.custom-record-select {
    .filter-content {
        display: flex;
        flex-direction: row;
        align-items: center;

        .clear-button {
            font-size: 18px;
            padding: 0px 3px;

            &:hover {
                color: var(--danger)
            }
        }
    }

    &.active {
        .filter-content {
            .clear-button {
                display: none;
            }
        }
    }
}