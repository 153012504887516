.sp-btn {
	--default-border: var(--primary);
	--primary-bg: var(--primary);
	--text-primary: #fff;
	--disabled-bg: var(--input-disabled-bg);
	--default-font-size: 1rem;

	height: calc(var(--ui-height-no-px) * 1em / 14);
	position: relative !important;

	svg {
		height: 18px;
		margin-top: 0;

		*[fill] {
			transition: all 150ms;
		}
	}

	span {
		margin-left: unset;
		transition: all 150ms;

		& + * {
			margin-left: 0;
		}
	}

	&.sp-btn-primary {
		text-transform: uppercase;
		font-weight: bold;
		--button-primary-disabled-text: var(--text-lighter);

		&:disabled {
			background-color: var(--disabled-bg);
			color: var(--button-primary-disabled-text);
		}

		&:hover {
			background-color: #279d4d;

			&:active {
				background: #279d4d;
			}
		}

		&:active {
			background-color: #279d4d;
		}
	}

	&.sp-btn-danger {
		--danger-bg: transparent;
		--danger-text: var(--danger);
		background: transparent;
		border: var(--border-width) solid var(--border-color);

		&:hover {
			background: rgba(205, 65, 65, 0.08);
			box-shadow: unset;

			&:active {
				background: rgba(205, 65, 65, 0.08);
			}
			filter: unset;
			// background-color: $hover-danger-button;
		}

		svg {
			*[fill] {
				fill: var(--danger-text) !important;
			}
		}
	}

	&.sp-btn-sm {
		font-size: 13px;
		max-height: 28px;
		text-transform: unset;
		font-weight: unset;
	}

	&.sp-btn-default {
		--default-border: var(--border-color);
		--default-bg: unset;
		--default-text: var(--pager-text);
		margin-left: 0;
		border-width: var(--border-width);
		display: inline-flex;
		align-items: center;
		cursor: pointer;
		gap: 10px;

		span {
			color: var(--text);
		}

		svg {
			width: calc(20 * 1em / 14);
			height: calc(20 * 1em / 14);

			*[fill] {
				transition: all var(--transition-timing);
				fill: var(--text-lighter);
			}
			*[stroke] {
				transition: all var(--transition-timing);
				stroke: var(--text-lighter);
			}
			rect[fill] {
				transition: all var(--transition-timing);
				fill: var(--content-bg);
			}
		}

		&.active {
			--default-bg: var(--secondary-light);
			--default-border: transparent;
			--default-text: var(--primary);
			svg {
				*[fill] {
					fill: var(--primary) !important;
				}
			}
		}
		&:active {
			background-color: unset !important;
		}

		&:hover {
			--default-border: var(--primary);
			color: var(--primary);
			filter: unset;
			box-shadow: unset;
			border-color: var(--primary);
			background: var(--button-hover-default-bg);

			svg {
				*[fill] {
					fill: var(--primary) !important;
				}
				rect[fill] {
					fill: var(--content-bg) !important;
				}
				*[stroke] {
					stroke: var(--primary) !important;
				}
			}

			span {
				color: var(--primary);
			}
		}

		&:disabled {
			border-color: transparent !important;

			span {
				color: var(--text-lighterer);
			}

			svg {
				*[fill] {
					fill: var(--disabled-text);
				}
				*[stroke] {
					stroke: var(--disabled-text);
				}
			}
		}
	}
}
