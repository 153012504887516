.workshop-wrapper {
	display: flex;
	gap: 85px;

	.fixed-page {
		width: 100%;
	}
	> .sp-row {
		flex: 1;
		width: 100%;
	}
	.warehouse-label {
		margin-bottom: 5px;
	}
}
.info-form {
	display: flex;
	gap: 85px;

	.fields {
		flex-basis: 380px;
	}
	> .sp-row {
		flex: 1;
	}
}
.workshop-form {
	.sp-row {
		.sp-col {
			max-width: unset !important;
		}
	}
}
@media (max-width: 1200px) {
	.workshop-wrapper {
		flex-direction: column;
	}
}
