.breadcrumbs {
  margin-bottom: 10px;
  .bc-arr,
  .bc-title,
  a {
    text-decoration: none;
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;
    color: var(--dark-grey);
  }
  span:last-child {
    span:last-child {
      text-decoration: underline;
    }
  }
}
