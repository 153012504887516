.navigation-menu {
	width: 100%;
	background-color: var(--main-bg);
	border-radius: var(--border-radius);
	overflow: hidden;

	.itemSelected {
		background-color: var(--secondary-bg);
	}

	nav {
		ul {
			list-style-type: none;

			li {
				cursor: pointer;
				transition: all var(--transition-timing) ease-out;

				.hover {
					display: flex;
					justify-content: space-between;
					align-items: center;

					.sp-popover {
						padding-right: 10px;
					}

					&:hover {
						background-color: var(--secondary-bg);
					}

					a {
						text-decoration: none;
						display: inline-block;
						padding: 8px 12px;
						width: 100%;

						&:hover {
							color: var(--text);
						}
					}
				}
			}

			.parent-li {
				.name {
					display: flex;
					justify-content: space-between;
					align-items: center;

					> :first-child {
						flex: 1;
					}
				}
			}
		}
	}
}
