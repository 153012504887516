.process-item {
	max-width: 975px;
	flex: 1;
	background-color: var(--main-bg);
	margin-bottom: 8px;
	border-radius: var(--border-radius);
	margin-top: 8px;

	.process-and-money {
		display: flex;
		gap: 20px;
		padding: 5px 20px;
		background: var(--section-header-color);
		border-radius: var(--border-radius);
		cursor: pointer;

		.icon-btn {
			svg {
				transition: var(--transition-timing);
			}
		}
		.down {
			svg {
				transform: rotate(180deg);
			}
		}
		.up {
			svg {
				transform: rotate(0deg);
			}
		}
		.ui-form {
			.sp-row {
				gap: 20px;
				display: grid;
				grid-template-columns: 1fr 8fr 4fr 2fr;
				align-items: center;
				margin-bottom: 0;
				.sp-col {
					min-width: unset;
					max-width: unset;
					.form-field {
						margin-bottom: 0;
					}
					.process-buttons {
						display: flex;
						align-items: center;
						justify-content: space-between;

						.cancel-button {
							margin-left: 20px;
							margin-right: 20px;
						}
						.delete-btn:not(.icon-btn) {
							margin-left: 20px;
						}
					}
				}
			}

			.form-submit {
				display: none;
			}
		}
	}

	.process-materials {
		height: auto;
		transition: max-height var(--transition-timing);
		overflow: hidden;

		.sp-table-container {
			padding: 0 10px;
			padding-bottom: 10px;

			.sp-table {
				tbody {
					.border {
						display: none;
					}
					tr {
						border: 0;

						td {
							height: unset;
							padding-top: 5px;
							padding-bottom: 5px;
							button {
								& + button {
									margin-left: 20px;
								}
							}
						}
						td:last-child {
							text-align: right;
						}
					}
				}
			}
		}
		.add-row {
			border-radius: 0 0 var(--border-radius) var(--border-radius);
		}
	}

	.materials-number {
		width: 60px;
	}

	.materials-quantity {
		width: 230px;
	}

	.materials-actions {
		width: 180px;
	}
}

.process-item-open {
	.process-and-money {
		border-radius: var(--border-radius) var(--border-radius) 0 0;
	}

	.process-materials {
		display: block;
	}

}
.process-item-close {
	.process-materials {
		display: none;
	}

}





@media (max-width: 1310px) {
	.process-item {
		.materials-number {
			width: 50px;
		}

		.materials-quantity {
			width: unset;
		}
		.materials-actions {
			//width: 50px;
		}
	}
}

@media (max-width: 1140px) {
	.process-item {
		overflow-x: auto;
		max-width: 530px;

		.process-and-money {
			min-width: 740px;
		}
		.process-materials {
			min-width: 740px;

			.sp-table-container {
				max-width: unset;
				overflow: unset;

				.materials-actions {
					width: unset;
				}
			}
		}
	}
}


@media (max-width: 1060px) {
	.process-item {
		max-width: unset;
	}
}

