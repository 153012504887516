.movements-buttons {
  margin: 10px 0 20px 0;
}

.info-section {
  background-color: var(--main-bg);
  margin-bottom: 20px;
  border-radius: var(--border-radius);

  .title {
    background-color: var(--section-header-color);
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    padding: 10px 20px;
    color: var(--pager-text);
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    font-weight: 500;

    span {
      font-weight: 500;
    }
  }
}

.common-fields {
  display: flex;
  margin: 10px 0;
  padding: 0 10px;
  justify-content: flex-start;
  gap: 10px;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
}

.field {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}

.fields-group {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  flex-wrap: wrap;
}

.button {
  margin: 10px;
}

.errors-block {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.button-group > * {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
