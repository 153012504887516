.kanban-skeleton {
	height: 100%;
	width: 100%;
	overflow: hidden;
	position: relative;

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 100%;
		background: linear-gradient(90deg, transparent, transparent, var(--skeleton-shine), transparent, transparent);
		animation-name: shimmer;
		animation-duration: 2.3s;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}

	@keyframes shimmer {
		0% {
			transform: translate(-100%);
		}
		70%,
		100% {
			transform: translate(100%);
		}
	}

	.kanban-skeleton-container {
		width: 100%;
		overflow: auto;
		display: grid;
		grid-template-columns: repeat(4, var(--list-width));
		gap: 20px;
	}

	.skeleton-list-column {
		height: 100%;
		width: var(--list-width);

		.skeleton-list-header {
			width: 100%;
			height: var(--ui-height);
			background-color: var(--skeleton-bg);
			border-radius: var(--border-radius);
			margin-bottom: 10px;
		}

		.skeleton-tasks-container {
			display: flex;
			flex-direction: column;
			gap: 10px;
			width: 100%;
			overflow: hidden;

			.skeleton-task {
				background-color: var(--skeleton-bg);
				height: 130px;
				width: 100%;
				border-radius: var(--border-radius);
			}
		}
	}
}
