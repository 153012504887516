.sp-avatar {
	border: 2px solid transparent;
	background-color: inherit;
	position: relative;

	.badge {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height: 17px;
		min-width: 17px;
		color: #fff;
		border: none;
		font-size: 13px;
		line-height: 15px;
		vertical-align: middle;
		background-color: var(--primary);
		right: -5px;
		top: -5px;
		border-radius: 20px;
	}
	&.selected {
		border-color: var(--primary);
	}

	.no-avatar {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		line-height: 100%;
		width: 100%;
	}
}
