.sp-table {
	--table-hdr-font-size: 11px;
	--table-hdr-bg: transparent;
	--table-hdr-text: var(--text);
	--table-border-color: var(--content-bg);
	--table-cell-bg: var(--main-bg);
	--table-cell-text: var(--text);
	--table-cell-hover-bg: var(--secondary-lighter);
	--table-cell-hover-text: var(--text);
	--table-cell-selected-bg: var(--secondary-light);
	--table-cell-selected-text: var(--text);
	--table-cell-padding: 10px;
	--table-error-text: var(--danger);

	thead {
		tr {
			&:nth-child(2) {
				.project-name_border {
					border-radius: var(--border-radius) 0 0 0;
				}
			}

			&:last-child {
				.project-name_border {
					border-radius: 0 0 0 var(--border-radius);
				}
			}

			th {
				.column-header {
					.column-title {
						color: var(--text-lighter);
						font-size: 12px;

						a {
							color: var(--text-lighter);
							font-size: 12px;
						}
					}
				}
				color: var(--text-lighter);
				font-size: 12px;
			}
		}
	}

	tbody {
		tr {
			&:nth-child(-n + 2) {
				td {
					&:first-child {
						border-radius: var(--border-radius) 0 0 0;
					}
					&:last-child {
						border-radius: 0 var(--border-radius) 0 0;
					}
				}
			}
			&:last-child {
				td {
					&:first-child {
						border-radius: 0 0 0 var(--border-radius);
					}
					&:last-child {
						border-radius: 0 0 var(--border-radius) 0;
					}
				}
			}
			td {
				height: 48px;

				&.cell-with-border {
					padding: 0;

					.string-value {
						height: 100%;
					}
				}

				.string-value {
					font-size: 14px;

					.avatars {
						flex-wrap: nowrap;
					}

					.project-name_border {
						min-width: 0;
						display: flex;
						align-items: center;
						height: 100%;
						border-left: 5px solid transparent;
						padding: 10px 10px 10px 16px;
					}

					.tasks-total-count {
						span {
							color: var(--text-lighter);
						}
					}
				}

				.box-label {
					display: inline-block;
					padding: 4px 12px;
					background-color: var(--light-blue);
					border-radius: 4px;
					color: var(--secondary);
				}
			}
		}
		tr:hover {
			td {
				.box-label {
					background-color: var(--secondary);
					color: #fff;
				}
			}
		}
	}
}

@media (max-width: 767px) {
	.table {
		overflow-x: auto;
		flex-grow: unset !important;

		.sp-table {
			table-layout: auto;
		}
	}
}
