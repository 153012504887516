.finance-operations {
	height: 100%;
	display: flex;
	flex-direction: column;
	padding-bottom: 10px;
	.toolbar {
		border-bottom: var(--border-width) solid var(--secondary-light);
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		column-gap: 20px;
		row-gap: 10px;
		margin-bottom: 10px;

		.filters {
			display: flex;
			flex-wrap: wrap;
			column-gap: 20px;
			row-gap: 10px;
		}
		.action-bar {
			display: flex;
			column-gap: 20px;
			margin-bottom: 10px;
		}
	}
	.charts,
	.charts-loading {
		height: 350px;
		padding: 20px;
		margin-bottom: 20px;
		background-color: var(--main-bg);
		border-radius: var(--border-radius);
		display: flex;
		.chart {
			flex-grow: 1;
		}
		.pie {
			width: 230px;
		}
	}
	tbody {
		tr {
			height: 50px;
		}
		.border {
			height: 0;
		}
	}

	.table {
		flex-grow: 1;
		display: flex;
		flex-direction: column;

		#operations-table {
			flex-grow: 1;
		}
	}
}
