.orders-toolbar {
	display: flex;
	border-bottom: var(--border-width) solid var(--border-color);
	gap: 15px;
	padding-bottom: 10px;
	margin-bottom: 10px;

	.top {
		display: flex;
		width: 100%;
		justify-content: space-between;
		gap: 20px;
		border-bottom: none;
		padding-bottom: 0;

		.left {
			display: flex;
			gap: 10px;
			flex-wrap: wrap;
			flex: 1;

			.toolbar-search {
				flex-basis: 380px;
			}

			.ui-input.price {
				width: 120px;
			}

			.list-select {
				min-width: 180px;
			}

			.items-select {
				min-width: 180px;
			}
		}
	}

	.middle {
		display: flex;
		gap: 10px;
		width: 100%;
		flex-wrap: wrap;

		.sp-date-picker {
			width: 170px;
		}
	}
}

@media (max-width: 767px) {
	.filters {
		margin-top: 15px;
	}
}
