input,
textarea {
	--input-hover-bg: var(--main-bg);
	--input-active-bg: var(--main-bg);
	--input-active-text: var(--text);
	--input-text: var(--text);

	border: var(--border-width) solid var(--border-color);

	&::placeholder {
		color: var(--text-lighter);
	}

	&:hover {
		color: var(--text);
		border-color: var(--border-color);
	}
}

input {
	font-size: var(--ui-filter-font-size);
	height: var(--ui-filter-height);
	padding: 12px;
}
