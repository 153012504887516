.finance-analitics {
	.toolbar {
		border-bottom: var(--border-width) solid var(--secondary-light);
		margin-bottom: 20px;
		.filters {
			display: flex;
			flex-wrap: wrap;
			column-gap: 20px;
			row-gap: 10px;
			margin-bottom: 20px;
		}
	}
	.charts {
		h2 {
			display: flex;
			justify-content: center;
		}
		& > .chart,
		.chart-loading {
			height: 350px;
			padding: 20px;
			margin-bottom: 20px;
			background-color: var(--main-bg);
			border-radius: var(--border-radius);
			.chart-no-data {
				height: 100%;
				font-weight: bold;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
}
