.sp-dropdown {
	--mult: calc(36em / 14); // размер в [px -> em], поделенный на размер шрифта [px -> number]
	--dropdown-trigger-text: var(--text);
	--dropdown-hover-bg: var(--secondary-lighter);
	--dropdown-hover-text: var(--text);
	--dropdown-selected-bg: var(--primary);
	--dropdown-selected-text: var(--text-primary);
	--dropdown-border: var(--border-color);
	--dropdown-text-light: var(--text-lighter);
	--dropdown-group-border: var(--border-color);

	--select-actions-bg: var(--main-bg-hover);
	--select-actions-text: var(--text);

	--pager-text: var(--text);
	--pager-link: var(--primary);
	--pager-font-size: 13px;
	--pager-active-text: var(--text);
	--pager-disabled-text: var(--text-lighter);
	--pager-hover-bg: var(--main-bg);
	--pager-hover-text: var(--text);

	font-size: 1rem;
	border: var(--border-width) solid var(--border-color);
	background-color: var(--dropdown-bg);
	color: var(--dropdown-text);
	transition: background var(--transition-timing);

	&.sp-dropdown-trigger {
		&.sm {
			height: 30px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			background-color: transparent;
			font-weight: 500;
			border-radius: var(--border-radius);
			cursor: pointer;
			min-width: 80px;

			.filter {
				&::before {
					top: 13px;
				}
			}
		}

		&.record-multi-select {
			.filter {
				min-height: var(--ui-filter-height) !important;

				.filter-content {
					.item-tag {
						display: inline-flex;
						align-items: center;
						cursor: default;
						vertical-align: unset;
						height: unset;

						.item-tag-content {
							white-space: pre-wrap;
							color: var(--text);
						}

						.remove-btn {
							width: 10px;
							height: 15px;
						}
					}
				}
			}
		}
	}

	.sp-dropdown-items {
		.sp-dropdown-item {
			font-size: 1rem;
			min-height: var(--ui-filter-height);
			justify-content: space-between;

			&:not(.selected) {
				.checkbox {
					display: none;
				}
			}

			.checkbox {
				order: 5;
			}

			&.selected.multiple {
				color: var(--secondary);
				gap: 5px;

				> * {
					color: var(--secondary);
				}
			}
		}
	}

	&.with-select-controls {
		.select-controls {
			> * {
				height: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 5px;
			}
		}
	}

	.filter {
		height: 100%;

		&-content {
			height: 100%;

			.search {
				padding: 0 5px;
			}

			.show-value {
				color: var(--text);
				padding: 0 5px;
				height: 100%;
				align-items: center;
				display: block;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	&-trigger.disabled {
		background: var(--dropdown-disabled-bg);
		border-color: var(--border-color);
		color: var(--input-disabled-text);
		filter: saturate(1);
	}

	&.sm {
		min-height: unset;
	}

	.ui-pager {
		margin-left: 5px;
		font-size: 0.785rem;
		a,
		span {
			display: flex;
			justify-content: center;
			align-items: center;
			border: none;

			border-radius: var(--border-radius);
			margin-right: 5px;
			width: 18px;
			height: 18px;
			color: var(--pager-text);
		}

		a {
			&:hover {
				border: none;
				background-color: transparent;
				color: var(--primary);
			}

			&.active {
				border: none;
				background-color: var(--border-color);
				color: var(--text);
			}

			&.disabled {
				color: var(--pager-disabled);
			}
		}
	}
}

.box-labels-multiselect {
	border: none !important;
	background-color: unset;

	// popover
	.multiple {
		svg {
			display: none !important;
		}
		.checkbox {
			display: none !important;
		}
	}

	.filter {
		cursor: default;
		width: 100%;

		.filter-arrow {
			display: none;
		}

		&-content {
			padding: 0 !important;

			.show-value {
				padding-left: 0px;
				font-size: 0px;
				display: inline !important;

				&:hover {
					filter: contrast(130%);
				}
				&:after {
					cursor: pointer;
					content: '+';

					display: inline-block;
					text-align: center;
					vertical-align: middle;
					line-height: 20px;
					width: 20px;
					margin-bottom: 12px;
					font-size: 16px;
					color: var(--text-primary) !important;
					background-color: var(--primary);
					border-radius: 50%;
				}
			}
		}
	}
}
.sp-portal {
	.box-labels-multiselect {
		background-color: var(--main-bg);
	}
}
