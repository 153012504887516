.sidebar {
	.menu {
		flex-grow: 1;
		overflow: auto;
		margin-top: 15px;
		position: relative;
		scrollbar-gutter: stable;

		.item {
			position: relative;
			display: flex;
			height: 34px;
			line-height: 34px;
			color: var(--text-light);
			font-weight: 500;
			text-decoration: none;
			border-left: 3px solid transparent;
			transition: all var(--transition-timing);

			.icon {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-basis: 20px;
				flex-shrink: 0;
				text-align: center;
				margin-right: 10px;
				margin-left: 10px;
				color: var(--text-light);

				svg {
					height: 16px;

					&.sidebar-react-icon {
						width: 20px;
						height: 16px;
					}

					*[stroke] {
						stroke: var(--text-light);
					}

					*[fill] {
						fill: var(--text-light);
					}
				}

				.no-icon {
					display: inline-block;
					width: 8px;
					height: 8px;
					border-radius: 50%;
					background: var(--text-light);
				}
			}

			& > .title {
				display: flex;
				gap: 15px;
				align-items: center;
				flex-basis: calc(var(--menu-width) - 50px);
				padding-right: 5px;
				position: relative;
				width: 100px;
				user-select: none;

				&.badge {
					.count {
						margin-right: 25px;
					}
				}
			}

			&:hover {
				text-decoration: none;
				border-color: var(--primary);
				background: var(--secondary-light);
			}

			&.active {
				border-color: var(--primary);
				color: var(--primary);

				.icon {
					color: var(--primary);

					svg {
						*[stroke] {
							stroke: var(--primary);
						}

						*[fill] {
							fill: var(--primary);
						}
					}
				}
			}
		}

		.submenu {
			margin-bottom: 15px;

			& > .title {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0 15px;
				text-transform: uppercase;
				user-select: none;
				margin-bottom: 5px;
				color: var(--text-lighter);
				cursor: pointer;

				& > .actions {
					display: flex;
					align-items: center;
					gap: 10px;

					.action-btn {
						cursor: pointer;

						&.disabled {
							display: none;
						}
					}
				}
			}
		}

		.hidden {
			.title {
				svg {
					transform: rotate(180deg);
				}
			}
		}
	}
}
