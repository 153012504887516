.default-theme {
	//*scss переменные - основные

	$dark: #323232;
	$white: #ffffff;
	$grey: #aeb2b7;
	$dark-grey: #7c7d84;
	$light-grey: #ecf3f9;
	$light-blue: #d8e8ff;
	$new-blue: #b0d4ff;
	$primary: #33ce65;
	$secondary: #2784ff;
	$skeleton-bg: #dfe6ec;

	$success: #{$primary};
	$danger: #cd4141;
	$info: #2784ff;
	$warning: #ff9900;

	//*scss переменные - производные

	$hover-danger-button: rgba($danger, 0.1);

	$login-background-layout: rgba($secondary, 0.7);
	$main-bg-hover: rgba($secondary, 0.05);
	$skeleton-shine: rgba($light-grey, 0.6);
	$button-default-hover-bg: rgba($new-blue, 0.3);
	$input-disabled-bg: #dce5ed;

	//*css переменные - основные цвета

	--dark: #{$dark};
	--dark-grey: #{$dark-grey};
	--grey: #{$grey};
	--white: #{$white};
	--light-grey: #{$light-grey};
	--light-blue: #{$light-blue};
	--new-blue: #{$new-blue};
	--primary: #{$primary};
	--secondary: #{$secondary};

	--success: #{$primary};
	--danger: #{$danger};
	--info: #{$info};
	--warning: #{$warning};

	//*css переменные - произвоные переменные

	--skeleton-shine: #{$skeleton-shine};
	--skeleton-bg: #{$skeleton-bg};

	--button-hover-default-bg: #{$button-default-hover-bg};

	--text: var(--dark);
	--text-light: var(--dark-grey);
	--text-lighter: var(--grey);
	--text-primary: var(--white);

	--pager-text: var(--dark-grey);
	--pager-disabled: var(--grey);

	--main-bg: var(--white);
	--main-bg-hover: #{$main-bg-hover}; //?!
	--content-bg: var(--light-grey);
	--secondary-bg: var(--light-blue);

	--primary-rgb: 51, 206, 101;

	--toolbar: var(--primary);
	--toolbar-text: var(--white);

	--secondary-light: var(--light-blue);
	--secondary-lighter: var(--light-blue);

	--border-color: var(--new-blue);

	--shadow: 0 1px 3px rgba(#000, 0.15);

	--icon-border: var(--secondary);
	--icon-fill: var(--light-blue);
	--icon-content: var(--primary);
	--icon-border-inactive: var(--icon-border);
	--icon-content-inactive: var(--dark-grey);
	--icon-disabled: var(--grey);

	--menu-width: 240px;

	--kanban-header-text: var(--white);
	--kanban-card-text: var(--grey);
	--kanban-parent-rgb: 236, 243, 249;

	--scrollbar-thumb: rgba(0, 0, 0, 0.1);
	--scrollbar-thumb-hover: rgba(0, 0, 0, 0.3);
	--scrollbar-track: transparent;

	--input-bg: var(--white);
	--input-disabled-bg: #{$input-disabled-bg};
	--input-text: var(--text);
	--input-disabled-text: var(--dark-grey);

	--dropdown-text: var(--text-light);
	--dropdown-bg: var(--white);
	--dropdown-disabled-bg: var(--input-disabled-bg);

	--placeholder: var(--dark-grey);

	--svg-attachment-color: var(--grey);

	--form-label-color: var(--dark-grey);

	--toggle-background: var(--primary);

	--section-header-color: var(--light-blue);

	--login-background-layout: #{$login-background-layout};

	background: var(--main-bg);

	// --hover-danger

	//?используется в ui-old
	--dropdown-bg: var(--main-bg);
}
