.layout {
	.content {
		display: flex;
		flex-direction: row;

		& > .page {
			margin-top: 50px;
			flex-grow: 1;
			padding: 20px 20px 20px 20px;
			height: calc(100vh - 50px);
			overflow: auto;
			background: var(--content-bg);
			transition: background var(--transition-timing);

			.page-title {
				margin: -15px -20px 15px -20px;
				padding: 0 20px;
				height: 50px;
				line-height: 50px;
			}
		}
	}
}

@media (max-width: 767px) {
	.layout {
		.content {
			.page {
				padding: 15px;
				.page-title {
					margin: 0;
					padding: 0;
				}
			}
		}
	}
}
