.orders-list {
  color: #f00;
  flex-grow: 1;
  position: relative;
  z-index: 1;

  #table {
    //height: unset;
  }

  .overdue {

    .date-value {
      color: #f00;
    }
  }
  .number-order{
    .string-value{
      color: var(--number-value);
      font-size: 11px;
    }
  }

  .wrapper-popover-trigger-table{

    svg{
      color: var(--secondary);
      font-size: 14px;
    }

    .sp-popover{
      cursor: pointer;
    }
  }
}
