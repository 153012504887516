.documents-kanban {
	height: calc(100% - 80px);
	margin-top: 10px;
	margin-bottom: -15px;

	.filters {
		height: var(--ui-filter-height);
		margin-bottom: 10px;

		input {
			width: 300px;

			&::placeholder {
				color: #aaa;
			}
		}
	}

	.kb-list-info {
		display: none !important;
	}

	.documents-kanban-container {
		height: calc(100% - 10px);
	}
}

.kb-item-content.document-kanban-card {
	.document-card-top {
		height: 24px;
		font-size: 11px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 7px;
		color: var(--text-lighter);

		.id {
			color: var(--secondary);
			flex-grow: 1;

			&.closed {
				color: var(--success);
			}
		}

		.last-update {
			text-align: right;
		}
	}

	.document-card-main {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 16px 5px 7px;
		line-height: 1.2em;

		.contragent-name {
			font-weight: 500;
			font-size: 1rem;
			line-height: 16px;
			margin-bottom: 8px;
			width: 180px;
			color: var(--text);
		}

		.id,
		.name {
			font-weight: 400;
			font-size: 12px;
			line-height: 14px;
			color: var(--text-lighter);
		}
	}

	.document-card-bottom {
		color: var(--text-lighter);
		border-top: 1px solid var(--border-color);
		padding: 5px 7px;
		display: flex;
		font-size: 12px;
	}
}

@media (max-width: 767px) {
	.documents-kanban {
		height: calc(100% - 60px);
	}
}
