.main-info {
	display: flex;
	column-gap: 110px;
	margin-top: 20px;

	hr {
		background-color: var(--secondary-light);
		height: var(--border-width);
	}

	.preview {
		flex-grow: 1;
		text-align-last: center;

		.preview-file {
			display: flex;
			height: 100%;
			flex-direction: column;
		}

		.preview-file {
			.preview-iframe {
				width: 100%;
				height: 100%;
				border: 0;
			}
		}
	}

	.document-form {
		flex-basis: 760px;

		.sp-row {
			.sp-col {
				.number-total {
					display: flex;
					gap: 20px;
				}

				.form-field {
					.filter-content {
						.show-value {
							color: var(--placeholder);

							> * {
								color: var(--text);
							}
						}

						.item-tag {
							font-size: 14px;
							background-color: var(--border-color);
							border-radius: var(--border-radius);

							svg {
								display: unset;
							}
						}
					}
				}
			}
		}
	}
}

.info-header {
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
}
