.nomenclature-list {
	flex-grow: 1;
	display: flex;
	flex-direction: column;

	.filters {
		display: flex;
		gap: 20px;
		margin-bottom: 10px;
		.onlyFavourites-checkbox,
		.showNegativeTotal-checkbox {
			margin-top: 5px;
		}
	}
}

.nomenclature-edit {
	.form-content {
		display: flex;
		flex-direction: row;
		gap: 115px;
		margin-top: 20px;
		.left {
			width: 380px;
			.favourite-checkbox {
				margin-top: 30px;
			}
			.sp-col {
				min-width: unset;
			}
		}
		.right {
			width: 100%;
			max-width: 580px;
			tbody {
				.border {
					display: none;
				}
			}
			tr {
				td,
				th {
					height: 30px;
					padding: 0 20px;
				}
				th {
					vertical-align: middle;
				}
			}
		}
	}
}
