.sp-tabs {
	--tab-text: var(--text-light);
	--tab-bg: transparent;
	--tab-parent-rgb: 255, 255, 255;
	--tab-container-border-color: var(--border-color);
	--tab-border-width: 2px;
	--tab-border-color: transparent;
	--tab-hover-text: var(--text);
	--tab-hover-border-color: #ccc;
	--tab-active-text: var(--text);
	--tab-active-border-color: var(--primary);
	--tab-active-bg: transparent;

	.sp-tab {
		.icon-title {
			svg {
				*[fill] {
					fill: var(--tab-text);
				}

				*[stroke] {
					stroke: var(--tab-text);
				}
			}

			&:hover {
				svg {
					*[fill] {
						fill: var(--tab-hover-text);
					}

					*[stroke] {
						stroke: var(--tab-hover-text);
					}
				}
			}
		}

		&.active {
			.icon-title {
				svg {
					*[fill] {
						fill: var(--tab-hover-text);
					}

					*[stroke] {
						stroke: var(--tab-hover-text);
					}
				}
			}
		}
	}
}
