.warehouse-items {
	display: flex;
	flex-direction: column;
	gap: 10px;
	.ui-form {
		.sp-row {
			gap: 10px;
			margin-bottom: 0;

			.sp-col {
				max-width: 380px !important;
			}
		}
		.form-submit {
			padding: 0;
		}
	}

	.warehouse-line {
		display: flex;
		gap: 10px;

		.warehouse-wrapper {
			max-width: 380px;
			width: 100%;
		}
		.warehouse-item-buttons {
			display: flex;
			gap: 10px;
		}
	}

	.warehouse-added {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 10px;

		.add-button.icon-btn {
			svg {
				*[fill] {
					fill: var(--primary);
				}
			}
		}

		.warehouse-wrapper {
			max-width: 380px;
			width: 100%;
		}
	}
}

@media (max-width: 700px) {
	.warehouse-items {
		.warehouse-line {
			flex-direction: column;
		}
	}
}
