@import 'drop-down';
@import 'button';
@import 'date-picker';
@import 'scrollbars';
@import 'form';
@import 'table';
@import 'popup';
@import 'input';
@import 'tabs';
@import 'button-group';
@import 'checkbox';
@import 'kanban';
@import 'avatar';
@import 'pager';
@import 'editor';
@import 'popover';
@import 'calendar';

.date-picker .react-datepicker-popper {
	z-index: 1500;
}

button.btn + button.btn {
	margin-left: 10px;
}

.sp-portal {
	z-index: 9000;
}
