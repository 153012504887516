.techRoadMap-create-form {
	width: unset;
	flex-basis: 450px;

	.sp-row {
		.sp-col {
			max-width: 100% !important;
		}
	}

}
.techRoadMapEdit-form-wrapper {
	display: flex;
	justify-content: flex-start;
	gap: 100px;
}

.techRoadMapEdit-processes-wrapper {
	flex: 1;
}

.sp-btn-primary.process-create {
	margin-top: 20px;
}

@media (max-width: 1310px) {
	.techRoadMapEdit-form-wrapper {
		gap: 30px;
	}
}

@media (max-width: 1060px) {
	.techRoadMapEdit-form-wrapper {
		flex-direction: column;
	}
}

