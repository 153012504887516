.orders-tiles {
	.pager-and-sort {
		margin-bottom: 15px;

		.ui-pager {
			display: flex;
			margin-right: 15px;

			.first-link {
				margin-left: 0;
			}
		}

		.sp-trigger.sp-dropdown {
			width: 200px;
		}
	}
}

.tiles-block {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(260px, 260px));
	gap: 15px;

	.order-tile {
		display: inline-block;
		vertical-align: top;
		width: 260px;
		height: 250px;
		padding: 10px;
		background: var(--main-bg);
		border: var(--border-width) solid var(--border-color);
		cursor: pointer;
		transition: all var(--transition-timing);
		border-radius: var(--border-radius);
		overflow-y: auto;

		&::-webkit-scrollbar{
			width: 5px;
		}

		&:hover {
			transform: translate(0, -1px);
			box-shadow: 0 3px 5px rgba(#000, 0.1);
		}

		.top {
			display: flex;
			color: var(--text-light);
			line-height: 14px;

			.id {
				flex-basis: 0;
				flex-shrink: 0;
			}

			.updated {
				flex-grow: 1;
				text-align: right;
				gap: 10px;
				display: flex;
				justify-content: flex-end;

				svg{
					color: var(--secondary);
				}

				.sp-popover{
					cursor: pointer;
				}
			}
		}

		.main {
			margin-top: 10px;

			.manager {
				float: right;
				margin-left: 10px;
				margin-bottom: 5px;
				border: var(--border-width) solid rgba(#000, 0.15);
			}

			.sp-popover{
				float: right;
			}

			.name {
				font-weight: bold;
				margin-bottom: 5px;
				word-break: break-word;
				max-height: 70px;
				overflow: hidden;
				white-space: pre-wrap;
				display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
			}

			.client {
				font-weight: bold;
			}

			.contragent, .paidStatus {
				font-size: 11px;
				color: var(--text-light);
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;

				em{
					font-style: normal;
					color: var(--secondary);
				}
				.sp-popover{
					float: none;
					display: inline;
					color: var(--secondary);
					margin-left: 3px;
				}
			}

			.list {
				margin-top: 5px;
				margin-bottom: 5px;

				span {
					display: inline-block;
					height: 24px;
					line-height: 22px;
					padding: 0 10px;
					border-radius: 4px;
					border: var(--border-width) solid rgba(#000, 0.1);
				}
			}

			.positions {
				color: var(--text-light);
				margin-top: 10px;
				margin-bottom: 5px;

				.position {
					display: flex;
					align-items: center;
					white-space: nowrap;
					line-height: 18px;

					.category {
						flex-grow: 1;
						flex-shrink: 1;
					}

					.amount {
						flex-shrink: 0;
						padding-left: 10px;
					}

					.price {
						padding-left: 10px;
						flex-shrink: 0;
						color: var(--text);
					}
				}
			}

			.total {
				display: flex;
				margin-bottom: 10px;

				.price {
					flex-grow: 1;

					em {
						color: var(--secondary);
						font-style: normal;
					}
				}

				.positions-count {
					flex-grow: 1;
					text-align: right;
				}
			}
		}
	}
}
