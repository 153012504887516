.my-companies {
	display: flex;
	flex-direction: column;
	gap: 20px;

	.contragent-card {
		width: 100%;
		background-color: var(--main-bg);
		border-radius: var(--border-radius);
		padding: 20px 20px 0 20px;

		.title {
			font-size: 16px;
			margin-bottom: 10px;
		}
		.bank-accounts {
			display: flex;
			flex-wrap: wrap;

			.bank-account {
				padding: 20px;
				height: 200px;
				width: 300px;
				gap: 20px;
				background-color: var(--secondary-bg);
				border-radius: var(--border-radius);
				border: var(--border-width) solid transparent;
				cursor: pointer;
				&:hover {
					border-color: var(--secondary) !important;
				}
			}
		}
	}
}
