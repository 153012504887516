.orders-kanban {
	--list-width: 260px;
	flex: 1;

	padding: 0 20px;
	overflow: hidden;
	margin: 0 -20px;

	.filters {
		height: 30px;
		margin-bottom: 10px;

		input {
			width: 300px;

			&::placeholder {
				color: #aaa;
			}
		}
	}

	.orders-kanban-container {
		height: 100%;
	}
}

.kb-item-content.order-kanban-card {
	.order-card-top {
		font-size: 12px;
		line-height: 12px;
		color: var(--text-light);
		display: flex;
		justify-content: space-between;
		margin-bottom: 8px;

		.id {
			&.closed {
				color: var(--success);
			}
		}

		.updated{
			flex-grow: 1;
			text-align: right;
			gap: 10px;
			display: flex;
			justify-content: flex-end;

			svg{
				color: var(--secondary);
				font-size: 14px;
			}

			.sp-popover{
				pointer-events: auto !important;
				cursor: pointer;
			}
		}
	}

	.order-card-main {
		line-height: 1.2em;

		.manager {
			float: right;
			margin-left: 10px;
			margin-bottom: 5px;
			border: var(--border-width) solid rgba(#000, 0.15);
		}

		.name {
			font-weight: bold;
			margin-bottom: 5px;
			color: var(--text);
		}

		.positions {
			clear: both;
			color: var(--text-light);
			margin-top: 10px;
			margin-bottom: 5px;

			.position {
				display: flex;
				align-items: center;
				white-space: nowrap;
				line-height: 18px;

				.category {
					flex-grow: 1;
					flex-shrink: 1;
				}

				.amount {
					flex-shrink: 0;
					padding-left: 10px;
				}

				.price {
					padding-left: 10px;
					flex-shrink: 0;
					color: var(--text);
				}
			}
		}

		.total {
			display: flex;
			margin-bottom: 10px;

			.price {
				flex-grow: 1;
				color: var(--text);

				em {
					color: var(--secondary);
					font-style: normal;
				}
			}

			.positions-count {
				flex-grow: 1;
				text-align: right;
				color: var(--text);
			}
		}

		.client, .paidStatus {
			font-size: 11px;
			color: var(--text-light);

			em {
				font-style: normal;
				color: var(--secondary);
			}
		}

		.sp-popover{
			float: right;
			pointer-events: auto !important;
		}
	}

	.order-card-bottom {
		border-top: 1px dotted var(--border-color);
		padding: 5px 7px;
		display: flex;
		font-size: 11px;

		.labels {
			flex-grow: 1;
		}
	}
}

.pager-and-sort-kanban {
	margin-bottom: 15px;

	.ui-pager {
		display: flex;
		margin-right: 15px;

		.first-link {
			margin-left: 0;
		}
	}

	.sp-trigger.sp-dropdown {
		width: 200px;
	}
}
