.warehouse-create-form {
  .sp-btn {
    padding: 0 24px;
  }
  .delete-btn {
    padding: 0 16px;
  }
  .form-submit {
    padding-top: 0;
  }
}
.warehouse-create-form-phone {
  max-width: 180px;
}
.toolbar-warehouses {
  padding-bottom: 8px;
  margin-bottom: 6px;
  border-bottom: var(--border-width) solid var(--border-color);
}

.table-remains {
  border: 1px solid #000;
  background: #fff;
  border-collapse: collapse;
  text-align: left;
  max-width: 800px;
  padding: 5px;
  margin: 10px 0;

  tr, td, th {
    border: 1px solid #E9E6E5;
    padding: 5px;
  }
}

.table-title {
  margin: 10px 0;
}
